<template>
    <master-panel>
        <template v-slot:body>
            <v-row v-if="invited" class="text-center">
                <v-col cols="12">
                    <v-icon v-text="'$check_circle'" size="70" color="secondary" class="mb-3"/>
                    <div class="subtitle">
                        Su invitación ha sido enviada con éxito, le
                        informaremos por esta vía cuando su
                        asistente haya ingresado a la plataforma.
                    </div>
                </v-col>
                <v-col cols="12" class="subtitle-1">
                    Si desea integrar a su secretaria, quien podrá tener
                    acceso a los datos de contacto del paciente candidato
                    a una cirugía, ingrésela ahora
                </v-col>
                <v-col cols="12" class="mt-7" v-if="showInviteButtons">
                    <v-btn v-if="roleId !== '2'" color="secondary" x-large depressed class="normal-btn"
                           @click="goToInvitations('2')">
                        Invitar a asistente clínico
                    </v-btn>
                    <v-btn v-else color="ternary" x-large depressed class="normal-btn"
                           @click="goToInvitations('3')">
                        Invitar a secretaria
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="subtitle">
                    Ingrese el nombre y correo de su {{ role }} y
                    automáticamente le llegará a esta persona, un
                    usuario y clave predeterminada, para que ingrese al
                    sistema y pueda tener acceso a los resultado de los
                    exámenes de sus pacientes.
                </v-col>
                <v-col cols="12">
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <kimsa-text-field
                                    label="Nombre"
                                    :rules="allRules.name"
                                    @change="name = $event"
                                    :value="name"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12">
                                <kimsa-text-field
                                    label="e-mail"
                                    :rules="allRules.email"
                                    @change="email = $event"
                                    :value="email"
                                    outlined dense
                                    inputMode="email"
                                />
                            </v-col>
                            <v-col cols="12">
                                <kimsa-text-field
                                    label="Confirmar e-mail"
                                    :rules="allRules.confirmEmail"
                                    @change="confirmEmail = $event"
                                    :value="confirmEmail"
                                    outlined dense
                                    inputMode="email"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn
                v-if="showContinueBtn" :loading="loading"
                :color="invited ? 'primary' : 'secondary'"
                x-large depressed class="normal-btn" @click="invite"
                :disabled="disabled"
            >
                {{ invited ? 'Siguiente' : 'Invitar' }}
            </v-btn>
            <v-btn v-else color="primary" x-large depressed class="normal-btn" @click="goBack">
                Volver
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import {rules} from "@/utils/Rules";
import httpService from "@/services/httpService";

export default {
    name: "InvitationsCreate",
    components: {KimsaTextField, MasterPanel},
    data() {
        return {
            valid: false,
            loading: false,
            name: '',
            email: '',
            confirmEmail: '',
            invited: false,
        }
    },
    computed: {
        roleId(vm = this) {
            return vm.$route.params.role || null
        },
        role(vm = this) {
            return vm.roleId === '2' ? 'asistente clínico' : 'secretaria'
        },
        allRules() {
            return {
                name: [rules.required, rules.minLength(this.name, 3)],
                email: [rules.required, rules.email],
                confirmEmail: [rules.required, rules.sameValue(this.confirmEmail, this.email, 'e-mail')]
            }
        },
        showContinueBtn(vm = this) {
            return !vm.currentUser?.completed_quiz || !vm.invited
        },
        disabled(vm = this) {
            return !vm.valid && !vm.invited
        },
        showInviteButtons(vm = this) {
            return !vm.currentUser?.assistant && !vm.currentUser?.secretary
        }
    },
    methods: {
        goToInvitations(roleId) {
            const vm = this
            vm.name = ''
            vm.email = ''
            vm.confirmEmail = ''
            vm.invited = false
            if (vm.roleId === roleId) return;
            vm.goTo({name: 'doctor.invitations.create', params: {role: roleId}})
        },
        async invite() {
            const vm = this
            if (vm.invited) return vm.goTo({name: 'doctor.invitations.finished'})
            vm.loading = true

            let response = await httpService.post('doctor/invite', {
                role_id: Number(vm.roleId),
                email: vm.email,
                name: vm.name,
            }).catch(err => {
                if (err.response.data.email) vm.toast('El email ya esta registrado', 'error')
                if (err.response.data.error) vm.toast(err.response.data.message, 'error')
            })

            // console.log('response', response)

            vm.loading = false
            if(response) vm.invited = true
        }
    },
}
</script>

<style scoped>

.subtitle {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.subtitle-1 {
    font-size: 12px !important;
    line-height: 16px;
    text-align: center;
}

</style>